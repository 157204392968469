// bhajanHP.js
import React from 'react';
import './style/bhajanHP.css'; // Make sure to import the CSS file

const BhajanHP = () => {
  return (
    <div className="body-container">
      <h2>This page is currently being constructed</h2>
      <p>Hare Krishna...</p>
    </div>
  );
};

export default BhajanHP;
